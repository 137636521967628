import React, { useEffect, useRef, useState } from "react";
import { format, roundToNearestMinutes, startOfDay, subDays } from "date-fns";
import { SunRiseIcon, SunSetIcon } from "../Common/Icons";
import { useLocations } from "../../providers/LocationsProvider";
import { useTheme } from "../../providers/ThemeProvider";
import useScaleRatio from "../../hooks/useScaleRatio";
import {
  calculateLengthOfDay,
  calculateSunrise,
  calculateSunset,
} from "../../utils";
import useInterval from "../../hooks/useInterval";
import { utcToZonedTime } from "date-fns-tz";
export default function SunDialContent({ sunDialChange }) {
  const { currentDate, currentLocation, customDate } = useLocations();

  const { theme } = useTheme();

  const scrollRef = useRef(null);

  const scaleRatio = useScaleRatio(scrollRef, 550);

  let dateFormatted = customDate ? format(customDate, "d.M.") : "tänään";
  let dayLength = currentLocation.daylightInfo.dayLength;
  let sunrise = currentLocation.daylightInfo.sunrise.time;
  let sunset = currentLocation.daylightInfo.sunset.time;

  sunrise = utcToZonedTime(sunrise, currentLocation.timeZone);
  sunset = utcToZonedTime(sunset, currentLocation.timeZone);

  let isMidnightSun =
    startOfDay(currentDate) >= currentLocation.midnightSun.start &&
    startOfDay(currentDate) <= currentLocation.midnightSun.end;
  let isPolarNight =
    startOfDay(currentDate) >= currentLocation.polarNight.start &&
    startOfDay(currentDate) <= currentLocation.polarNight.end;

  if (sunDialChange) {
    const comparedDate = subDays(currentDate, sunDialChange);
    dateFormatted = format(comparedDate, "d.M.");

    sunrise = calculateSunrise(
      currentLocation.latitude,
      currentLocation.longitude,
      comparedDate
    ).time;
    sunset = calculateSunset(
      currentLocation.latitude,
      currentLocation.longitude,
      comparedDate
    ).time;

    sunrise = utcToZonedTime(sunrise, currentLocation.timeZone);
    sunset = utcToZonedTime(sunset, currentLocation.timeZone);

    dayLength = calculateLengthOfDay(
      currentLocation.latitude,
      currentLocation.longitude,
      comparedDate
    );

    isMidnightSun = dayLength === 24 * 60 * 60;
    isPolarNight = dayLength === 0;
  }

  let sunriseLabel = "Auringonnousu";
  let sunsetLabel = "Auringonlasku";

  let sunriseFormatted = format(roundToNearestMinutes(sunrise), "HH:mm");
  let sunsetFormatted = format(roundToNearestMinutes(sunset), "HH:mm");

  const dayLengthHours = Math.floor(dayLength / 60 / 60);
  const dayLengthMinutes = Math.round(dayLength / 60) % 60;

  if (isMidnightSun) {
    sunriseFormatted = format(currentLocation.midnightSun.start, "d.M.");
    sunsetFormatted = format(currentLocation.midnightSun.end, "d.M.");
  }

  if (isPolarNight) {
    sunriseFormatted = format(currentLocation.polarNight.end, "d.M.");
    sunsetFormatted = format(currentLocation.polarNight.start, "d.M.");
  }

  const bgGradient =
    theme === "dark"
      ? "radial-gradient(#00377430 50%, #003774ff 100%)"
      : "radial-gradient(#00dcfa1a 50%, #0050c680 100%)";

  function calcFontScaling(initSize) {
    return `calc(${initSize}px - ${initSize * (1 - scaleRatio)}px)`;
  }
  return (
    <div
      ref={scrollRef}
      style={{
        top: "14%",
        left: "14%",
        width: "72%",
        height: "72%",
        backgroundImage: bgGradient,
      }}
      className="
        absolute 
        rounded-full
        pl-1
        border-2 md:border-4 border-heading-light/50 dark:border-day-light/50          
      "
    >
      {/* <div
        className="
        absolute 
        top-0 left-0
        w-full h-full
        rounded-full
        bg-day-light

        "
        style={{ opacity: opa }}
      ></div> */}
      <div
        className="
          flex flex-col items-center justify-center 
          w-full h-full
          pt-2 sm:pt-6
        "
      >
        <span
          className="
            block 
            border-b-2 sm:border-b-[3px] border-dotted border-heading-light/50 
            pb-3 xs:pb-4 
            font-extrabold leading-5 tracking-tight 
            text-heading-light dark:text-heading-dark 
            text-shadow-sm shadow-day-dark dark:shadow-day-dark/30 
          overflow-hidden
            "
          style={{
            fontSize: calcFontScaling(26),
          }}
        >
          Päivän pituus <span className="inline-block">{dateFormatted}</span>
        </span>
        {isMidnightSun && (
          <span
            style={{
              fontSize: calcFontScaling(86),
            }}
            className="
              block
              mt-[4vw] mb-[4vw] sm:my-7
              font-extrabold leading-none tracking-tighter whitespace-nowrap 
              text-white 
              text-shadow-lg shadow-day-dark dark:shadow-day-dark/30
            "
          >
            Yötön yö
          </span>
        )}
        {isPolarNight && (
          <span
            style={{
              fontSize: calcFontScaling(86),
            }}
            className="
              block
              mt-[4vw] mb-[4vw] sm:my-7
              font-extrabold leading-none tracking-tighter whitespace-nowrap 
              text-white 
              text-shadow-lg shadow-day-dark dark:shadow-day-dark/30
            "
          >
            Kaamos
          </span>
        )}
        {!isMidnightSun && !isPolarNight && (
          <div
            className="
            flex items-center 
            mt-[2.5vw] mb-[3vw] ml-4 sm:my-5
            text-shadow-lg shadow-day-dark dark:shadow-day-dark/30 
          "
          >
            <span
              className="
                block 
                font-extrabold leading-none tracking-tight whitespace-nowrap 
                text-white
              "
              style={{
                fontSize: calcFontScaling(95),
              }}
            >
              {dayLengthHours}
            </span>
            <span
              className="
                block 
                mx-2 
                font-extrabold whitespace-nowrap 
                text-heading-light dark:text-heading-dark 
                z-10

              "
              style={{
                fontSize: calcFontScaling(50),
              }}
            >
              h
            </span>
            <span
              className="
              block 
              font-extrabold leading-none tracking-tight whitespace-nowrap 
              text-white
            "
              style={{
                fontSize: calcFontScaling(95),
              }}
            >
              {dayLengthMinutes}
            </span>
            <span
              className="
              block 
              ml-1 
              font-extrabold whitespace-nowrap 
              text-heading-light dark:text-heading-dark 
              z-10
            "
              style={{
                fontSize: calcFontScaling(40),
              }}
            >
              min
            </span>
          </div>
        )}
        <div className="flex items-center justify-between gap-2 xs:gap-4">
          <div className="flex flex-col items-center">
            <span
              className="
                block 
                font-extrabold tracking-tight whitespace-nowrap 
                text-heading-light dark:text-heading-dark 
                text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
              "
              style={{
                fontSize: calcFontScaling(20),
              }}
            >
              {sunriseLabel}
            </span>
            <span
              className="
                  flex items-center 
                  mt-1 ml-2 pt-1 
                  border-t-2 border-dotted border-heading-light/50 
                  font-extrabold 
                  text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
                  overflow-hidden

                "
              style={{
                fontSize: calcFontScaling(30),
              }}
            >
              <div
                className="mr-2 md:mr-3"
                style={{
                  width: "5.5vw",
                  height: "5.5vw",
                  maxWidth: "32px",
                  maxHeight: "32px",
                }}
              >
                <SunRiseIcon
                  width="100%"
                  height="100%"
                  fill="yellow"
                  stroke="yellow"
                />
              </div>
              <span>{sunriseFormatted}</span>
            </span>
          </div>

          <div className="flex flex-col items-center">
            <span
              className="
                block 
                font-extrabold tracking-tight whitespace-nowrap 
                text-heading-light dark:text-heading-dark 
                text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
              "
              style={{
                fontSize: calcFontScaling(20),
              }}
            >
              {sunsetLabel}
            </span>
            <span
              className="
                flex items-center 
                mt-1 mr-2 pt-1 
                border-t-2 border-dotted border-heading-light/50 
                font-extrabold 
                text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
                overflow-hidden
              "
              style={{
                fontSize: calcFontScaling(30),
              }}
            >
              <div
                className="mr-2 md:mr-3"
                style={{
                  width: "5.5vw",
                  height: "5.5vw",
                  maxWidth: "32px",
                  maxHeight: "32px",
                }}
              >
                <SunSetIcon
                  width="100%"
                  height="100%"
                  fill="yellow"
                  stroke="yellow"
                />
              </div>
              <span>{sunsetFormatted}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
