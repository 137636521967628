import React, { useEffect, useState } from "react";
import { useModal } from "../../providers/ModalProvider";
import { useLocations } from "../../providers/LocationsProvider";
import Select from "react-select";
import { useTheme } from "../../providers/ThemeProvider";

import { menuStyles } from "../../dropmenustyles";
import { CloseIcon } from "../Common/Icons";

export default function CustomDateModal() {
  const { changeDate } = useLocations();

  const { closeModal } = useModal();
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const { theme } = useTheme();

  useEffect(() => {
    const today = new Date();
    setDay(`${today.getDate() < 10 ? "0" + today.getDate() : today.getDate()}`);
    setMonth(
      `${
        today.getMonth() < 10
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1
      }`
    );
  }, []);

  const menuColors = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#003074aa" : "#0060aaaa",
      borderRadius: "12px",
      border: "none",
      padding: "0.6rem 0.4rem 0.6rem 1rem",
      "@media only screen and (max-width: 400px)": {
        padding: "0.4rem 0.2rem 0.4rem 0.8rem",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#6df",
      };
    },
  };

  const months = [
    {
      value: "01",
      label: "Tammikuu",
    },
    {
      value: "02",
      label: "Helmikuu",
    },
    {
      value: "03",
      label: "Maaliskuu",
    },
    {
      value: "04",
      label: "Huhtikuu",
    },
    {
      value: "05",
      label: "Toukokuu",
    },
    {
      value: "06",
      label: "Kesäkuu",
    },
    {
      value: "07",
      label: "Heinäkuu",
    },
    {
      value: "08",
      label: "Elokuu",
    },
    {
      value: "09",
      label: "Syyskuu",
    },
    {
      value: "10",
      label: "Lokakuu",
    },
    {
      value: "11",
      label: "Marraskuu",
    },
    {
      value: "12",
      label: "Joulukuu",
    },
  ];

  const days = Array(31)
    .fill({})
    .map((d, idx) => ({
      value: `${idx < 9 ? "0" + (idx + 1) : idx + 1}`,
      label: `${idx + 1}.`,
    }));

  return (
    <div className="w-full sm:w-[400px] p-4 xs:py-6 bg-day-dark rounded-2xl ">
      <button className="absolute top-4 right-4" onClick={closeModal}>
        <CloseIcon width="24px" height="24px" stroke="#aef" fill="none" />
      </button>
      <h2 className="text-xl font-extrabold mb-4 text-center">
        Valitse päivämäärä
      </h2>
      <div className="flex justify-between items-center gap-2 w-full mb-4">
        <div className="w-24 text-gray-600">
          <Select
            name="day"
            id="day"
            options={days}
            value={days.find((item) => item.value === day)}
            placeholder="Päivä"
            onChange={(selected) => setDay(selected.value)}
            styles={{ ...menuStyles, ...menuColors }}
            isSearchable={false}
            aria-label="Valitse päivä"
          />
        </div>
        <div className="w-48 flex-1 text-gray-600">
          <Select
            name="month"
            id="month"
            options={months}
            value={months.find((item) => item.value === month)}
            placeholder="Kuukausi"
            onChange={(selected) => setMonth(selected.value)}
            styles={{ ...menuStyles, ...menuColors }}
            isSearchable={false}
            aria-label="Valitse kuukausi"
          />
        </div>
      </div>
      <div className="flex justify-center items-center w-full  ">
        <button
          onClick={() => {
            closeModal();
            changeDate(month, day);
          }}
          className="
              py-3 px-8 
              rounded-full 
              bg-day-darker/75  
              hover:bg-day-darker  
              transition-colors 
              disabled:opacity-50 disabled:pointer-events-none 
            "
        >
          OK
        </button>
      </div>
    </div>
  );
}
