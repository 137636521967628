import React, { useState } from "react";

import LocationSelectionMenu from "./LocationSelectionMenu";
import { CloseIcon, SearchIcon, ShareIcon } from "../Common/Icons";
import logo from "../../assets/logo.svg";

import { useModal } from "../../providers/ModalProvider";
import ShareImageModal from "../Modals/ShareImageModal";

const Button = ({ children, clicked, label, plEvent }) => (
  <button
    className={`
      flex items-center justify-center flex-shrink-0 
      w-9 h-9 xs:w-10 xs:h-10 
      rounded-full 
      p-0 
      bg-day-darker/70 dark:bg-[#004084]
      hover:bg-night-dark/70 dark:hover:bg-night-light 
      transition-colors
      plausible-event-name=${plEvent}`}
    onClick={clicked}
    aria-label={label}
  >
    {children}
  </button>
);

export default function Header() {
  const [locationsMenuVisible, setLocationsMenuVisible] = useState(false);

  const { openModal } = useModal();

  return (
    <div
      className="
      flex justify-center items-center 
      w-full h-[70px] xs:h-20 md:h-[90px] 
      py-0 px-4 xs:px-5 md:px-8 md:mb-5
    "
    >
      <div className="flex justify-center sm:justify-between items-center w-full">
        <div className="md:order-2">
          <Button
            type="button"
            clicked={() => openModal(<ShareImageModal />)}
            label="Jaa päivän tiedot kuvana"
            plEvent="Share"
          >
            <ShareIcon width="50%" height="50%" fill="none" stroke="#3cf" />
          </Button>
        </div>
        <div className="flex justify-center md:justify-between items-center w-full">
          <div
            className={`${locationsMenuVisible ? "hidden" : "block"} md:block`}
          >
            <img
              src={logo}
              alt="PäivänPituus.fi"
              className="w-[180px] h-[36px] xs:w-[220px] xs:h-[44px] md:w-[250px] md:h-[50px] lg:w-[275px] lg:h-[55px]"
            />
          </div>

          <div
            className={`${
              locationsMenuVisible ? "block" : "hidden"
            } md:block w-full max-w-xs mx-4 md:w-60 md:ml-auto md:mr-6`}
          >
            <LocationSelectionMenu
              close={() => setLocationsMenuVisible(false)}
            />
          </div>
        </div>
        <div className="md:hidden">
          <Button
            type="button"
            clicked={() => setLocationsMenuVisible(!locationsMenuVisible)}
            label="Valitse paikkakunta"
          >
            {locationsMenuVisible ? (
              <CloseIcon width="50%" height="50%" fill="none" stroke="#3cf" />
            ) : (
              <SearchIcon width="50%" height="50%" fill="none" stroke="#3cf" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
